<template>
  <div class="credit-usage-container" :style="bodyStyle">
    <div>
      <el-row :gutter="20" type="flex" align="center">
        <el-col :span="5">
          <div>
            <el-statistic
              group-separator=","
              :precision="2"
              :value="totalCredit"
              title="总点数"
            >
              <template #formatter>
                {{ isPlusType ? "不限" : totalCredit }}
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col
          :span="1"
          style="
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div>-</div>
        </el-col>
        <el-col :span="5">
          <div>
            <el-statistic
              group-separator=","
              :precision="2"
              :value="usageCredit"
              title="使用点数"
            ></el-statistic>
          </div>
        </el-col>
        <el-col
          :span="1"
          style="
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div>=</div>
        </el-col>
        <el-col :span="5">
          <div>
            <el-statistic
              group-separator=","
              :precision="2"
              :value="remainCredit"
              title="剩余点数"
            >
              <template #formatter>
                <span v-if="remainCredit < 0" style="color: red">
                  {{ isPlusType ? "不限" : remainCredit }}
                </span>
                <span v-else>{{ isPlusType ? "不限" : remainCredit }}</span>
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col
          :span="6"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div>
            <el-button
              icon="el-icon-coin"
              round
              type="success"
              @click="handleBuyAiPackage"
            >
              点数充值
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" align="center" style="margin-top: 1rem">
        <el-col :span="24" style="text-align: center">
          点数规则：抠图和图片翻译6点/每张，文本翻译60点/一万字符，生成报告20点/每次
        </el-col>
      </el-row>
    </div>

    <el-divider></el-divider>
    <el-row type="flex" justify="center">
      <el-table :data="tableData" align="center">
        <el-table-column prop="createdDate" label="时间" align="center">
          <template #default="{ row }">
            {{ row.createdDate | formatDateTime }}</template
          >
        </el-table-column>
        <el-table-column
          prop="name"
          label="项目名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          align="center"
        ></el-table-column>
        <el-table-column prop="credit" label="点数" align="center">
          <template #default="{ row }">
            {{ row.type === "PLUS" ? "+" : "-" }}
            {{ row.credit }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 1rem">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="paginationLayout"
        :total="totalSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <div>
      <buy-ai-package
        :dialog-visible.sync="status.showBuyAiPackage"
      ></buy-ai-package>
    </div>
  </div>
</template>

<script>
import { tableMixin } from "@/common/js/mixin";
import { getCreditUsage, getCreditUsageDetail } from "../../api/credit-usage";
import buyAiPackage from "@/pages/tools/views/dialog/buy-ai-package.vue";
import { mapGetters } from "vuex";

export default {
  name: "CreditUsage",
  components: { buyAiPackage },
  mixins: [tableMixin],

  props: {
    bodyStyle: {
      type: Object,
      default: () => ({
        minWidth: "75rem",
      }),
    },
  },

  data() {
    return {
      status: {
        showBuyAiPackage: false,
      },
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      //   pageSetting: {
      //     total: this.totalSize,
      //     currentPage: this.currentPage,
      //     pageSize: this.pageSize,
      //   },

      totalCredit: null,
      usageCredit: null,
      remainCredit: null,
    };
  },

  computed: {
    ...mapGetters(["isPlusType"]),
  },

  created() {
    getCreditUsage().then((res) => {
      this.totalCredit = res?.data?.creditLimit;
      this.usageCredit = res?.data?.creditUsage;
      this.remainCredit = (
        res?.data.creditLimit - res?.data?.creditUsage
      ).toFixed(2);
    });
    this.search();
  },
  methods: {
    handleBuyAiPackage() {
      this.status.showBuyAiPackage = true;
    },
    originSearch() {
      getCreditUsageDetail(this.query).then((res) => {
        this.tableData = res.data?.records;
        this.totalSize = res.data?.total;
      });
    },
  },
};
</script>

<style scoped>
.credit-usage-container {
  /* min-width: 60rem; */
  max-width: 100rem;
  margin: 1.5rem auto;
}
</style>
